import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'

import { Image } from 'src/components/image'
import { RenderModules } from 'src/utils/renderModules'
import { SEO } from 'src/components/seo'

import { SmallArrow } from 'src/components/svgs'
import { ButtonBlock } from 'src/components/inline/buttonBlock'
import Content from 'src/components/content'

export interface ServiceProps {
  pageContext: {
    main: {
      modules: []
      slug: {
        current: string
      }
      title: string
    }
    meta: {}
    env: 'production' | 'development' | 'local' | 'staging'
  }
  path: string
}

const Service = ({ path, pageContext }: ServiceProps) => {
  const {
    main: {
      title,
      appointmentLink,
      shortDescription,
      serviceImage,
      backgroundColor,
      modules,
      slug,
    },
    meta,
    env,
  } = pageContext

  const url = path

  return (
    <div
      className={cx('service', backgroundColor, {
        bcotint: backgroundColor === undefined,
      })}
    >
      <SEO metaInfo={meta} pagePath={path} env={env} />
      <div className="bg-secondary-2">
        <div className="container--l x outer cb al mxa bold back__link p1">
          <Link to="/services" className="f jcs aic tertiary-3">
            <SmallArrow className="block rotate" /> All services
          </Link>
        </div>
      </div>
      <div className="container--l f jcb aic fw mxa outer p1 pym">
        <Image
          className="x col-m-4"
          imageId={serviceImage.asset._id}
          alt={title}
        />
        <div className="x col-m-6">
          <h1 className="h2">{title} at Tia</h1>
          <p className="ss18 sm25">{shortDescription}</p>
          {appointmentLink && <ButtonBlock data={appointmentLink} />}
          <div className="mt1">
            <a
              href={`${process.env.GATSBY_WIDGET_URL?.split('/widget')[0]}`}
              className="underline o5"
            >
              Already a Tia member? Book here
            </a>
          </div>
        </div>
        <div className="mt1 x">
          <div
            className="cta__squiggle service black mt1 x"
            style={{ backgroundImage: 'url(/squiggle-black.png)' }}
          />
        </div>
      </div>
      <Content modules={modules}></Content>
    </div>
  )
}

export default Service
